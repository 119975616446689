<template>
  <v-footer color="#ffd606" style="opacity: 0.4" padless>
    <v-col class="text-center" cols="12">
      <span>&#169; </span>{{ new Date().getFullYear() }} —
      <strong>La Belle</strong>
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: "FooterEtiket",

  data: () => ({}),
};
</script>
