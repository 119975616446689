<template>
  <v-app>
    <v-app-bar app color="#ffd606" dense elevate-on-scroll>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title
        ><a href="/" style="color: black; font-size: 22px"
          >La Belle</a
        ></v-toolbar-title
      >

      <v-spacer></v-spacer>

      <v-btn icon>
        <a href="https://www.facebook.com/profile.php?id=100016616682419">
          <v-icon color="blue">mdi-facebook</v-icon>
        </a>
      </v-btn>
      <v-btn icon>
        <a href="https://www.instagram.com/la_belle_waiblingen/">
          <v-icon color="pink">mdi-instagram</v-icon>
        </a>
      </v-btn>
      <v-btn icon>
        <a href="Tel:+491629739166">
          <v-icon color="green">mdi-phone</v-icon>
        </a>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      app
      class="spacingTop"
      v-model="drawer"
      color="#ffd606"
      fixed
      bottom
      temporary
    >
      <v-list nav dense>
        <v-list-item class="menu">
          <v-list-item-content>
            <router-link class="menulinks" to="/">
              <v-list-item-title style="color: black; font-size: 15px">
                Home
              </v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="menu">
          <v-list-item-content>
            <router-link class="menulinks" to="/gallery">
              <v-list-item-title style="color: black; font-size: 15px">
                Bildergalerie
              </v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="menu">
          <v-list-item-content>
            <router-link class="menulinks" to="/services">
              <v-list-item-title style="color: black; font-size: 15px">
                Services
              </v-list-item-title>
            </router-link>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="menu">
          <v-list-item-content>
            <router-link class="menulinks" to="/contact"
              ><v-list-item-title style="color: black; font-size: 15px"
                >Kontakt</v-list-item-title
              ></router-link
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>
    <Footer class="mt-16" bottom fixed />
  </v-app>
</template>

<script>
import Footer from "./components/Footer.vue";

export default {
  name: "App",

  metaInfo: {
    title: 'Kosmetik: Kosmetikstudio, Schönheit, Hautreinigung, Kryolipolyse, Abnehmen, labelle, mary kay'
  },

  components: {
    Footer,
  },

  data: () => ({
    drawer: false,
    dialog: true,
  }),

  watch: {
    group() {
      this.drawer = false;
    },
  },
};
</script>

<style>
.navbarLinks {
  color: black;
  font-size: 18px;
}

.menu:hover {
  background-color: white;
  color: gold;
}

nav {
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-left);
}

.spacingTop {
  margin-top: 3em;
}

a:link,
a:visited,
.menulinks:visited {
  text-decoration: none;
}
</style>
